<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/about">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.aboutTeam") }}</li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_text">
        <div class="box">
          <template v-if="locale === 'en'">
            Amazon (hereinafter referred to as Amazon, Amazon), is the largest
            online e-commerce company in the United States, located in Seattle,
            Washington. Amazon is one of the first companies on the Internet to
            start operating e-commerce. Amazon was established in 1995. At the
            beginning, it only operated online book sales. Now it has expanded
            to a wide range of other products and has become the world's largest
            online product variety Retailers and global Internet companies
          </template>
          <template v-if="locale === 'pb'">
            Amazônia (a seguir denominada Amazônia, Amazônia), é a maior empresa
            de comércio electrónico online nos Estados Unidos, localizada em
            Seattle, Washington. A Amazon é uma das primeiras empresas na
            Internet a começam a operar o comércio electrónico. A Amazônia foi
            criada em 1995. Na começando, apenas operava vendas de livros
            online. Agora ela se expandiu para uma ampla gama de outros produtos
            e se tornou o maior do mundo variedade de produtos online Retailers
            e empresas globais da Internet
          </template>
          <br />
          <br />
          <StaticImage url="about/about_04.png" />
          <div class="logoDiv">
            <img
              class="logo"
              :src="
                sysDataDictionaryWbObj.TXHQDZ?.dataContent +
                sysDataDictionaryWbObj.SJDLOGO?.dataContent
              "
            />
          </div>

          <br />
          <br />
          <template v-if="locale === 'en'">
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} is an important
            strategic partner of Amazon's cross-border e-commerce. Nine major
            sites in the world, eight major sites have been opened to global
            sellers. {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}'s eight
            global sites have accumulated more than millions of corporate and
            institutional buyers. These buyers cover users in hospitals,
            laboratories, daycare centers and other users. According to the
            latest statistics of Amazon Business USA, compared with the same
            period in 2020, buyers in different market segments have achieved
            amazing growth in
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} purchases in 2021.
          </template>

          <template v-if="locale === 'pb'">
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} é importante
            parceiro estratégico do comércio electrónico transfronteiriço da
            Amazônia. Nove grandes lugares no mundo, oito grandes lugares foram
            abertos a nível global vendedores.
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }}' s oito sites
            globais acumularam mais de milhões de empresas e compradores
            institucionais. These buyers cover users in hospitals, laboratórios,
            guarda-dia e outros usuários. De acordo com a última estatísticas da
            Amazon Business USA, comparadas com o mesmo período em 2020,
            compradores em diferentes segmentos de mercado alcançaram um
            crescimento incrível in
            {{ sysDataDictionaryWbObj?.WZMC?.dataContent }} compras em 2021.
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StaticImage from "@/components/StaticImage.vue";
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { StaticImage },
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style lang="scss" scoped>
.logoDiv {
  width: 100%;
  text-align: center;
  overflow: visible;
  height: 0px;
  .logo {
    height: 50px;
    width: 50px;
    margin-top:-300px;
  }
}
</style>